import axios from '@/utils/request'

// 账单管理-账单查询
export function getBillsData(params) {
  // params.loading = true
  return axios({
    url: `/manager/data/bill/bills`,
    method: 'get',
    params
  })
}
//  账单管理-账单下载
export function downloadBills(params) {
  return axios({
    responseType:'blob',
    url: `/manager/data/bill/bills/download`,
    method: 'post',
    params
  })
}

// 账单管理-资金汇总
export function getSummaryData(params) {
  // params.loading = true
  return axios({
    url: `/manager/data/bill/summary`,
    method: 'get',
    params
  })
}
// 账单管理-汇总导出
export function exportSummary(params) {
  return axios({
    responseType:'blob',
    url: `/manager/data/bill/summary/export`,
    method: 'post',
    params
  })
}
// 账单管理-资金汇总(分账)
export function getAllocateData(params) {
  // params.loading = true
  return axios({
    url: `/manager/data/bill/allocate/summary`,
    method: 'get',
    params
  })
}
// 账单管理-资金汇总(分账)
export function setResetRun(data) {
  return axios({
    url: `/manager/data/bill/bills/replay`,
    method: 'get',
    params:data,
    data
  })
}
// 账单管理-账单导出
export function exportAllocateSummary(params) {
  return axios({
    responseType:'blob',
    url: `/manager/data/bill/allocate/summary/export`,
    method: 'post',
    params
  })
}